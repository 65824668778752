import React, { Component } from 'react'
import { Button, Checkbox, Spin, Switch, message } from "antd"
import { getSettings, saveSettings } from "../../../services/api/SettingServices"
import PropTypes from "prop-types"
import { ListSupplier1C, ListSupplier1CDisable } from "../helpers/listSupplier1C"

const CheckboxGroup = Checkbox.Group
const listSupplier = [
    { name: 'supplierPrintforia', field: 'supplier_printforia', label: 'Printforia' },
    { name: 'supplierYoco', field: 'supplier_yoco', label: 'Yoco (Cailian)' },
    { name: 'supplierBg', field: 'supplier_bg', label: 'BaseGift' },
    { name: 'supplierPleu', field: 'supplier_pleu', label: 'PL EU' },
    { name: 'supplierCW', field: 'supplier_cw', label: 'ColorWay' },
    { name: 'supplierDBUS', field: 'supplier_dbus', label: 'Dubow US' },
    { name: 'supplierMD', field: 'supplier_md', label: 'Monsters Digital' },
    { name: 'supplierDPCN', field: 'supplier_dpcn', label: 'Dogerprint (DPCN)' },
    { name: 'supplierSPUS', field: 'supplier_spus', label: 'SwiftPOD (SPUS)' },
    { name: 'supplierPBAU', field: 'supplier_pbau', label: 'The Print Bar (PBAU)' },
    { name: 'supplierDTUS', field: 'supplier_dtus', label: 'District Photo (DTUS)' },
    { name: 'supplierHFT71', field: 'supplier_hft71', label: 'HFT71' },
    { name: 'supplierGLUS', field: 'supplier_glus', label: 'Gear Launch (GLUS)' },
    { name: 'supplierDFUS', field: 'supplier_dfus', label: 'Drive Fulfillment (DFUS)' },
    { name: 'supplierPYUS', field: 'supplier_pyus', label: 'Brown Production Platform (PYUS)' },
    { name: 'supplierEFUS', field: 'supplier_efus', label: 'Order Desk(EFUS)' },
    // { name: 'supplierMOUS', field: 'supplier_mous', label: 'MerchOne (MOUS)' },
    { name: 'supplierGTUS', field: 'supplier_gtus', label: 'Gooten (GTUS)' },
    { name: 'supplierMPUS', field: 'supplier_mpus', label: 'MPUS' },
    { name: 'supplierFSUS', field: 'supplier_fsus', label: 'FlashShip POD (FSUS)' },
    { name: 'supplierTCVN', field: 'supplier_tcvn', label: 'Toàn Cầu (TCVN)' },
    { name: 'supplierMCVN', field: 'supplier_mcvn', label: 'MCVN' },
    { name: 'supplierTEEU', field: 'supplier_teeu', label: 'TEEU' },
    { name: 'supplierBFUS', field: 'supplier_bfus', label: 'BFUS' },
    { name: 'supplierCC', field: 'supplier_cc', label: 'CustomCat (CC)' },
    { name: 'supplierPCUK', field: 'supplier_pcuk', label: 'Print Clever' },
    { name: 'supplierMCUS', field: 'supplier_mcus', label: 'MCUS' },
    { name: 'supplierDODUS', field: 'supplier_dodus', label: 'DODUS' },
    { name: 'supplierSCUS', field: 'supplier_scus', label: 'SpokeCustom (SCUS)' },
    { name: 'supplierDFWUS', field: 'supplier_dfwus', label: 'DFWUS' },
]

CheckboxGroup.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.any
}

class AutoPushContent extends Component {
    state = {
        loading: false,
        error: '',
        loadingUpdate: false,
        errorUpdate: '',
        autoPush: true,
        supplier1C: {
            modeNormal: true,
            modeBuiltPayment: true,
            modeMarketplace: false,
            printHolo: true
        },
        checkAll: true,
        indeterminate: false,
        listSelected: ['modeNormal', 'modeBuiltPayment', 'printHolo']
    }

    componentDidMount() {
        this._fetchSettingAutoPush()
    }

    _checkSetting = (value) => {
        if (typeof value === 'string') {
            if (value === 'true') {
                return true
            } else if (value === 'false') {
                return false
            }
        } else {
            return value
        }

    }

    _fetchSettingAutoPush = async () => {

        this.setState({
            loading: true,
            error: ''
        })

        try {
            const response = await getSettings('autopush')

            const { success, data, message } = response


            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            const {
                platform_mode_normal,
                platform_mode_built_in_payment,
                platform_printholo,
                auto_push,
            } = data

            const suppliers = {}

            listSupplier.forEach(i => { suppliers[i.name] = this._checkSetting(data[i.field]) })

            this.setState({
                loading: false,
                autoPush: this._checkSetting(auto_push),
                supplier1C: {
                    modeNormal: this._checkSetting(platform_mode_normal),
                    modeBuiltPayment: this._checkSetting(platform_mode_built_in_payment),
                    modeMarketplace: false,
                    printHolo: this._checkSetting(platform_printholo)
                },
                ...suppliers
            }, () => {
                const { supplier1C, autoPush } = this.state
                const unCheckSuppliers = {}

                listSupplier.forEach(i => { unCheckSuppliers[i.name] = false })

                if (!autoPush) {
                    this.setState({
                        checkAll: false,
                        indeterminate: false,
                        supplier1C: {
                            modeNormal: false,
                            modeBuiltPayment: false,
                            modeMarketplace: false,
                            printHolo: false
                        },
                        listSelected: [],
                        ...unCheckSuppliers,
                    })
                } else {
                    let listSelected = []

                    Object.keys(supplier1C).map(item => {
                        if (supplier1C[item]) {
                            return listSelected.push(item)
                        } else {
                            return []
                        }
                    })

                    this.setState({
                        listSelected: listSelected,
                        checkAll: !!listSelected.length && listSelected.length === ListSupplier1C.length,
                        indeterminate: !!listSelected.length && listSelected.length < ListSupplier1C.length
                    })
                }
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _handleTogglePush = (checked) => {
        this.setState({
            autoPush: checked
        }, () => {
            const { autoPush } = this.state

            if (!autoPush) {
                const unCheckSuppliers = {}

                listSupplier.forEach(i => { unCheckSuppliers[i.name] = false })

                this.setState({
                    supplier1C: {
                        modeNormal: false,
                        modeBuiltPayment: false,
                        modeMarketplace: false,
                        printHolo: false
                    },
                    ...unCheckSuppliers,
                    checkAll: false,
                    indeterminate: false,
                    listSelected: []
                })
            } else {
                const checkSuppliers = {}

                listSupplier.forEach(i => { checkSuppliers[i.name] = true })

                this.setState({
                    supplier1C: {
                        modeNormal: true,
                        modeBuiltPayment: true,
                        modeMarketplace: false,
                        printHolo: true
                    },
                    ...checkSuppliers,
                    checkAll: true,
                    indeterminate: false,
                    listSelected: ['modeNormal', 'modeBuiltPayment', 'printHolo']
                })
            }
        })
    }

    _handleChangeCheckBox = (e) => {
        const { name, checked } = e.target
        const { supplier1C } = this.state
        const listSupplierName = listSupplier.map(i => i.name)

        if (!listSupplierName.includes(name)) {
            this.setState({
                supplier1C: {
                    ...supplier1C,
                    [name]: checked
                }
            })
        } else {
            this.setState({
                [name]: checked
            })
        }
    }

    _onUpdateSetting = async () => {
        this.setState({
            loadingUpdate: true,
            errorUpdate: ''
        })

        try {
            const {
                supplier1C,
                autoPush,
            } = this.state
            const { modeNormal, modeBuiltPayment, modeMarketplace, printHolo } = supplier1C
            const payload = {
                auto_push: autoPush,
                platform_mode_normal: modeNormal,
                platform_mode_built_in_payment: modeBuiltPayment,
                platform_mode_marketplace: modeMarketplace,
                platform_printholo: printHolo,
            }

            listSupplier.forEach(i => { payload[i.field] = this.state[i.name] })

            const response = await saveSettings(payload)

            const { success, message: msgError } = response

            if (!success) {
                return this.setState({
                    loadingUpdate: false,
                    errorUpdate: msgError
                })
            }

            this.setState({
                loadingUpdate: false,
                errorUpdate: ''
            }, () => {
                message.success('Update settings success!')
            })
        } catch (e) {
            this.setState({
                loadingUpdate: false,
                errorUpdate: e.message
            })
        }
    }

    _onChange = (list) => {
        this.setState({
            listSelected: list,
            checkAll: !!list.length && list.length === ListSupplier1C.length,
            indeterminate: !!list.length && list.length < ListSupplier1C.length
        }, () => {
            const { listSelected } = this.state

            const listActive = {}
            const listDeactive = {}

            const listUnCheck = ListSupplier1C.map(item => {
                if (!listSelected.includes(item.value)) {
                    return item.value
                } else {
                    return null
                }
            })

            listSelected.map(item => {
                return Object.assign(listActive, { [item]: true })
            })

            listUnCheck.map(item => {
                if (!!item) {
                    return Object.assign(listDeactive, { [item]: false })
                } else {
                    return null
                }
            })

            this.setState({
                supplier1C: {
                    ...listDeactive,
                    ...listActive,
                    modeMarketplace: false
                }
            })
        })
    }

    _onCheckAllChange = (e) => {
        const { checked } = e.target

        this.setState({
            checkAll: checked,
            indeterminate: false,
            supplier1C: {
                modeNormal: checked,
                modeBuiltPayment: checked,
                modeMarketplace: false,
                printHolo: checked
            },
            listSelected: checked ? ['modeNormal', 'modeBuiltPayment', 'printHolo'] : []
        })
    }

    render() {
        const { disableAll } = this.props
        const {
            loading,
            error,
            autoPush,
            loadingUpdate,
            errorUpdate,
            indeterminate,
            checkAll,
            listSelected
        } = this.state

        if (error) {
            return <div className='AutoPushContent text-danger'>{error}</div>
        }
        const length1c = ListSupplier1C.length + ListSupplier1CDisable.length + 1
        const totalPerList = (listSupplier.length + length1c) / 3 <= 12 ? 12 : Math.ceil((listSupplier.length + length1c) / 3)

        return (
            <Spin spinning={loading}>
                <div className='AutoPushContent'>
                    <div className="TogglePush">
                        <label htmlFor="AutoPush">Auto Push</label>
                        <Switch id='AutoPush' checked={autoPush} onChange={this._handleTogglePush} disabled={disableAll} />
                    </div>

                    <p className='title'>
                        Chọn supplier áp dụng Auto push
                    </p>

                    <div className="d-flex flex-wrap">
                        <div className="list-1">
                            <div className="CheckBoxGroup">
                                <div className="ModeCheckGroup">
                                    <Checkbox disabled={!autoPush || disableAll} indeterminate={indeterminate} checked={checkAll}
                                        onChange={this._onCheckAllChange}>
                                        1C
                                    </Checkbox>
                                </div>
                                <CheckboxGroup disabled={!autoPush || disableAll} options={ListSupplier1C} value={listSelected}
                                    onChange={this._onChange} />
                                <CheckboxGroup disabled options={ListSupplier1CDisable} />
                            </div>
                            {listSupplier.map((i, index) => (
                                index < (totalPerList - length1c) &&
                                <div className="ModeCheck" key={`L1_${index}`}>
                                    <Checkbox
                                        disabled={!autoPush || disableAll}
                                        name={i.name}
                                        checked={this.state[i.name]}
                                        onChange={this._handleChangeCheckBox}
                                    >
                                        {i.label}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                        <div className="list-2">
                            {listSupplier.map((i, index) => (
                                (index >= (totalPerList - length1c) && index < (2 * totalPerList - length1c)) &&
                                <div className="ModeCheck" key={`L2_${index}`}>
                                    <Checkbox
                                        disabled={!autoPush || disableAll}
                                        name={i.name}
                                        checked={this.state[i.name]}
                                        onChange={this._handleChangeCheckBox}
                                    >
                                        {i.label}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                        <div className="list-3">
                            {listSupplier.map((i, index) => (
                                (index >= (2 * totalPerList - length1c)) &&
                                <div className="ModeCheck" key={`L3_${index}`}>
                                    <Checkbox
                                        disabled={!autoPush || disableAll}
                                        name={i.name}
                                        checked={this.state[i.name]}
                                        onChange={this._handleChangeCheckBox}
                                    >
                                        {i.label}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </div>
                    <hr />
                    <div className="Action text-right">
                        {
                            !!errorUpdate && <div className="text-danger mr-3">{errorUpdate}</div>
                        }
                        {!disableAll &&
                            <Button type='primary' loading={loadingUpdate} size='large' disabled={disableAll}
                                onClick={this._onUpdateSetting}>Save</Button>
                        }
                    </div>
                </div>
            </Spin>
        )
    }
}

export default AutoPushContent
