import React, { useState, useEffect } from 'react'
import TagRequestUpdate from "./TagRequestUpdate"
import { Tag, message, Tooltip, Modal } from 'antd'
import { updatePrintFilesStatus } from '../../../../services/api/OrdersServices'
import { ReactComponent as IconRetry } from "../helpers/retry-cutting.svg"
import TagAllow from '../helpers/TagAllow'

const ProcessingTime = ({ processingAt, hovering }) => {
    const [formattedTime, setFormattedTime] = useState('')

    const calculateTime = () => {
        const startTime = new Date(processingAt)
        const endTime = new Date()
        const processingTime = endTime - startTime
        const hours = Math.floor(processingTime / (1000 * 60 * 60))
        const minutes = Math.floor((processingTime % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((processingTime % (1000 * 60)) / 1000)

        return `${!!hours ? String(hours).padStart(2, '0') + 'h' : ''} ${!!minutes ? String(minutes).padStart(2, '0') + 'm' : ''} ${!!seconds ? String(seconds).padStart(2, '0') + 's' : ''}`
    }

    useEffect(() => {
        if (hovering) {
            const newTime = calculateTime()
            setFormattedTime(newTime)
        }
    }, [hovering, processingAt])

    return formattedTime
}


const ItemStatus = (props) => {
    const { status, is_archive, tags, orderId, fulfillmentId, onChangeStatus, processingAt, itemsRequest, orderItem } = props
    const [hovering, setHovering] = useState(false)

    const _mapStatus = (status, note) => {
        if (!status) {
            return null
        }

        let output = ''
        switch (status) {
            case 'error':
            case 'error_design':
                output = <span className="badge badge-danger badge-pill">{status}</span>
                break
            case 'done':
                output = <span className="badge badge-success badge-pill">{status}</span>
                break
            case 'pending':
            case 'retry':
                output = <span
                    className="badge badge-info badge-pill progress-bar-animated progress-bar-striped">{status}</span>
                break
            case 'processing':
                output = (!processingAt) ?
                    <span className="badge badge-primary badge-pill progress-bar-animated progress-bar-striped">{status}</span> :
                    <Tooltip
                        placement='topLeft'
                        title={<ProcessingTime processingAt={processingAt} hovering={hovering} />}
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                    >
                        <span className="badge badge-primary badge-pill progress-bar-animated progress-bar-striped cursor-pointer">{status}</span>
                    </Tooltip>
                break
            case 'hold':
                output = <span className="badge badge-secondary badge-pill">{status}</span>
                break
            case 'review':
                output = <span
                    className="badge badge-warning badge-pill progress-bar-animated progress-bar-striped">{status}</span>
                break
            case "prepare":
                output = <Tag style={{ borderRadius: '8px', fontWeight: 600 }} color="#3ba2b9">{status}</Tag>
                break
            case "cutting":
                output = <Tag style={{ borderRadius: '8px', fontWeight: 600 }} color="#2196F3">{status}</Tag>
                break
            case "cut_failed":
                output = <div className='d-flex aligin-items-center'>
                    <Tag style={{ borderRadius: '8px', fontWeight: 600 }} color="#F43838">{status}</Tag>
                    <Tooltip title="Retry cut artwork">
                        <IconRetry
                            width={20}
                            height={20}
                            className='cursor-pointer'
                            onClick={() => handleRetryCutting()}
                        />
                    </Tooltip>
                </div>
                break
            case "retry_cutting":
                output = <Tag style={{ borderRadius: '8px', fontWeight: 600 }} color="#F5B40B">{status}</Tag>
                break
            default:
                output = status
        }

        return output
    }

    const handleRetryCutting = async () => {
        try {
            const { success, data, message: mess } = await updatePrintFilesStatus(fulfillmentId, orderId, 'retry_cutting')
            if (!success) {
                throw new Error(mess)
            }
            message.success('Print file retry success!!!')
            onChangeStatus('retry_cutting', orderId)
        } catch (error) {
            Modal.error({
                title: 'Retry cutting error',
                content: error.message
            })
        }
    }

    return (
        <td className='ItemStatus'>
            {_mapStatus(status, '')}

            {
                !!tags && tags.length > 0 &&
                <div className="TagRequest">
                    {
                        tags.map((tag, index) => {
                            if (TagAllow.includes(tag)) return <TagRequestUpdate tag={tag} orderId={orderId} key={index} itemsRequest={itemsRequest} orderItem={orderItem} />
                        })
                    }
                </div>
            }
        </td>
    )
}

export default ItemStatus