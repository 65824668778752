import React, { useState } from 'react'
import { Button, Modal, notification } from "antd"
import { cancelOrderSupplier } from "../../../../services/api/ExportOrderServices"
import broker from "../../OrderBroker"
import * as lodash from 'lodash'

const CONSTANTS = {
    'BFUS': {
        status: ['pending'],
        params: 'bfus',
    },
    // 'TEEU': {
    //     status: ['on_hold', 'pending'],
    //     params: 'teeu',
    // },
    // 'CC': {
    //     status: ['pending'],
    //     params: 'cc',
    // },
    'PCUK': {
        status: ['pending'],
        params: 'pcuk',
    },
    'DODUS': {
        status: ['in_production'],
        params: 'dodus',
    },
    'SCUS': {
        status: ['pending'],
        params: 'scus',
    },
}

const CancelOrderSupplier = (props) => {
    const { fulfillment, showPushButton } = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const supplier = lodash.get(fulfillment, 'supplier.sku_prefix')

    const config = CONSTANTS[supplier]

    const onCloseModal = () => {
        setIsOpen(false)
    }

    const onOpenModal = () => {
        setIsOpen(true)
    }

    const _onCancelOrder = async () => {
        setLoading(true)
        setError('')

        try {

            const response = await cancelOrderSupplier(config.params, fulfillment._id)

            const { success, message } = response

            if (!success) return setError(message)

            broker.publish("TRACKING_UPDATED")
            broker.publish('ORDER_REFRESHED_ORDER')
            broker.publish('ORDER_REFRESH_BRANDING')
            broker.publish('ORDER_RESTORE')

            notification.success({
                message: 'Cancel order success',
                description: `Cancel order ${supplier} success.`,
            })
            onCloseModal()

        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    return (!!config && config.status.includes(lodash.get(fulfillment, 'factory_info.status')) && showPushButton) && (
        <>
            <button onClick={onOpenModal} className="btn btn-danger mr-3">
                Cancel {supplier}
            </button>
            <Modal
                visible={isOpen}
                title={`Cancel order ${supplier}`}
                onOk={_onCancelOrder}
                onCancel={onCloseModal}
                footer={[
                    <div key="content" className='d-flex align-items-center justify-content-between'>
                        <Button key="back" onClick={onCloseModal}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="danger" loading={loading} onClick={_onCancelOrder}>
                            Submit
                        </Button>
                    </div>
                ]}
            >
                <p>Do you want to cancel <strong>{fulfillment.name}</strong>?</p>
                {
                    !!error && error.length > 0 && <div className="text-danger">{error}</div>
                }
            </Modal>
        </>
    )
}

export default CancelOrderSupplier
