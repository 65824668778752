import React, { PureComponent } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'
import UserInfo from '../../shared/UserInfo'
import { Tooltip, Popover, Badge } from 'antd'
import { getLocalData } from "../../../services/StorageServices"
import {
    RightCircleOutlined,
    LeftCircleOutlined,
    RightOutlined,
    LeftOutlined
} from '@ant-design/icons'

const navbar = [
    {
        title: 'Home',
        fa: 'ti-home',
        href: '/a/analytics',
        permissions: 'ffm_dashboard_read',
    },
    {
        title: 'Orders',
        fa: 'ti-pencil-alt',
        href: '/a/orders',
        permissions: 'ffm_order_view',
    },
    {
        title: 'Package Management',
        fa: 'ti-shopping-cart-full',
        href: '/a/orders-supplier',
        permissions: 'ffm_package_view',
    },
    {
        title: 'Package Issues',
        fa: 'ti-alert',
        href: '/a/package-issues',
        // permissions:'',
    },
    {
        title: 'Print Files',
        fa: 'ti-printer',
        href: '/a/print-files',
        permissions:'ffm_printing_file_read',
    },
    {
        title: 'Product Line',
        fa: 'ti-archive',
        href: '/a/products',
        permissions: 'ffm_product_line_read',
    },
    // {
    //     title: 'Artworks',
    //     fa: 'ti-palette',
    //     href: '/a/artworks',
    //     permissions: 'ffm_artwork_read',
    // },
    {
        title: 'Suppliers',
        fa: 'ti-truck',
        href: '/a/suppliers',
        permissions: 'ffm_supplier_read',
    },
    {
        title: 'Shipping Plans',
        fa: 'ti-calendar',
        href: '/a/shipping',
        permissions: 'ffm_shipping_plan_read',
    },
    {
        title: 'Shipping Zones',
        fa: 'ti-map-alt',
        href: '/a/shipping-zones',
        permissions: 'ffm_shipping_zone_read',
    },
    {
        title: 'Shipping Fee',
        fa: 'ti-package',
        href: '/a/shipping-fee',
        permissions: 'ffm_shipping_fee_read',
    },
    {
        title: 'Fulfillment Discounts',
        fa: 'ti-credit-card',
        href: '/a/fulfillment-discounts',
        permissions: 'ffm_fulfillment_discounts_read',
    },
    {
        title: 'Fulfillment Variants',
        fa: 'ti-agenda',
        href: '/a/list-variants',
        permissions: 'ffm_fulfillment_variants_read',
    },
    {
        title: 'Mapping condition',
        fa: 'ti-direction-alt',
        href: '/a/mapping-condition',
        permissions: 'ffm_mapping_condition_read',
    },
    {
        title: 'Charge/Refund',
        fa: 'ti-money',
        href: '/a/charge-refund',
        permissions: 'ffm_charge_and_refund_view_filter',
    },
    {
        title: 'Mapping Variants',
        fa: 'ti-control-shuffle',
        href: '/a/mapping-variants',
        permissions: 'ffm_catalog_variant_read',
    },
    {
        title: 'Package Splitting Rules',
        fa: 'ti-layers-alt',
        href: '/a/package-splitting-rules',
        permissions:'ffm_view_split_package_rule',
    },
]

const navbarAdmin = [
    {
        title: 'Requests',
        fa: 'ti-location-arrow',
        href: '/a/requests',
        permissions: 'ffm_request_update_read',
    },
    {
        title: 'Cost Control',
        fa: 'ti-panel',
        href: '/a/cost-control',
        permissions: 'ffm_cost_control_read',
    },
    {
        title: 'Order Profit',
        fa: 'ti-medall',
        href: '/a/order-profit',
        permissions: 'ffm_order_profit_all',
    },
    {
        title: 'Mapping Rules',
        fa: 'ti-direction',
        href: '/a/mapping-rules',
        permissions: 'ffm_mapping_rules_read',
    }
]

class NavbarLeft extends PureComponent {

    render() {
        const {totalsFailed, handleShowOrderFailed, totalFBAWarning, permissionsData, isCollapsed, totalManiFestWarning, totalIssueConfirm, totalIssueChecking, onOpenIssueConfirm, totalIssueReplace} = this.props
        const user = getLocalData('user')
        const totalNotiIssue = totalIssueChecking + totalIssueReplace
        const totalNotiOrder = totalsFailed + totalFBAWarning + totalManiFestWarning
        const navbarAdminShow = permissionsData.includes('ffm_request_update_read') || permissionsData.includes('ffm_cost_control_read') ||
            permissionsData.includes('ffm_order_profit_all') || permissionsData.includes('ffm_mapping_rules_read')

        return (
            <div className="NavbarLeft">
                <div className="NavbarMask" onClick={this.props.onClick}>
                    {
                        isCollapsed ? <RightOutlined style={{ fontSize: '12px', color: '#FFF' }} /> : <LeftOutlined style={{ fontSize: '12px', color: '#FFF' }} />
                    }

                </div>
                <div className='NavbarLeftBox'>
                    <div className="NavbarLeftInner d-flex justify-content-between">
                        <nav className="PrimaryMenu">
                            {
                                navbar.map((nav) => {
                                    return ((permissionsData.includes(nav.permissions) || !nav.permissions) &&
                                        <NavLink to={nav.href} className="nav-link"
                                            activeClassName="active" key={nav.href}>
                                            {
                                                isCollapsed ? <Tooltip overlayClassName='nav-item-tooltip' title={nav.title} placement="right">
                                                    <span className="Icon IconHolder">
                                                        <i className={nav.fa} />
                                                    </span>
                                                </Tooltip> : <>
                                                    <span className="Icon IconHolder">
                                                        <i className={nav.fa} />
                                                    </span>
                                                </>
                                            }
                                            {
                                                nav.title === "Orders" ?
                                                <>
                                                    <span className="Title">{nav.title}</span>
                                                    <Badge 
                                                        count={totalNotiOrder|| 0} 
                                                        onClick={handleShowOrderFailed} 
                                                        className={isCollapsed ? "BadgePosition" : "BadgePosition expand"}
                                                        style={{right: totalNotiOrder > 99 ? -8 : 6}}
                                                        title='Orders warning messages'
                                                    /> 
                                                </> :
                                                    nav.title === "Package Issues" ?
                                                    <>
                                                        <span className="Title">{nav.title}</span>
                                                        <Badge
                                                            count={totalNotiIssue}
                                                            onClick={onOpenIssueConfirm}
                                                            className="BadgePosition issue-badge"
                                                            style={{right: totalNotiIssue > 99 ? -6 : (totalNotiIssue > 9 ? -2 : 5)}}
                                                            title='Package issue notification'
                                                        />
                                                    </>:
                                                        <span className="Title">{nav.title}</span>
                                            }
                                            {
                                                'label' in nav &&
                                                <span className="Label badge badge-primary ml-auto">{nav.label}</span>
                                            }
                                        </NavLink>
                                    )
                                })
                            }

                            {(navbarAdminShow) &&
                                navbarAdmin.map((nav) => {
                                    return (permissionsData.includes(nav.permissions) &&
                                        <NavLink to={nav.href} className="nav-link"
                                            activeClassName="active" key={nav.href}>
                                            {
                                                isCollapsed ? <Tooltip overlayClassName='nav-item-tooltip' title={nav.title} placement="right">
                                                    <span className="Icon IconHolder">
                                                        <i className={nav.fa} />
                                                    </span>
                                                </Tooltip> : <>
                                                    <span className="Icon IconHolder">
                                                        <i className={nav.fa} />
                                                    </span>
                                                </>
                                            }
                                            {
                                                nav.title === "Orders" ?
                                                    <>
                                                        <span className="Title">{nav.title}</span>
                                                        <Badge
                                                            count={(totalsFailed + totalFBAWarning + totalManiFestWarning) || 0}
                                                            onClick={handleShowOrderFailed}
                                                            className={isCollapsed ? "BadgePosition" : "BadgePosition expand"}
                                                            title='Orders warning messages'
                                                        />
                                                    </> :
                                                    nav.title === "Package Issues" ?
                                                    <>
                                                        <span className="Title">{nav.title}</span>
                                                        <Badge
                                                            count={totalIssueChecking}
                                                            onClick={onOpenIssueConfirm}
                                                            className="BadgePosition issue-badge"
                                                            title='Package issue notification'
                                                        />
                                                    </> :
                                                    <span className="Title">{nav.title}</span>
                                            }
                                            {
                                                'label' in nav &&
                                                <span className="Label badge badge-primary ml-auto">{nav.label}</span>
                                            }
                                        </NavLink>
                                    )
                                })
                            }
                        </nav>
                        <nav className="BottomMenu">
                            {permissionsData.includes('ffm_notification_read') &&
                                <NavLink to="/a/notification" className="nav-link" activeClassName="active">
                                    {
                                        isCollapsed ? <Tooltip overlayClassName='nav-item-tooltip' title="Notification" placement="right">
                                            <span className="Icon IconHolder">
                                                <i className="ti-bell" />
                                            </span>
                                            <span className="Title">Notification</span>
                                        </Tooltip> : <>
                                            <span className="Icon IconHolder">
                                                <i className="ti-bell" />
                                            </span>
                                            <span className="Title">Notification</span>
                                        </>
                                    }
                                    {/* <span className="Icon IconHolder" data-tooltip="Notification">
                                    <i className="ti-bell"/>
                                </span>
                                <span className="Title">Notification</span> */}
                                </NavLink>
                            }
                            {permissionsData.includes('ffm_webhook_job_read') &&
                                <NavLink to="/a/webhook-jobs" className="nav-link" activeClassName="active">
                                    {
                                        isCollapsed ? <Tooltip overlayClassName='nav-item-tooltip' title="Webhook Jobs" placement="right">
                                            <span className="Icon IconHolder">
                                                <i className="ti-stack-overflow" />
                                            </span>
                                            <span className="Title">Webhook Jobs</span>
                                        </Tooltip> : <>
                                            <span className="Icon IconHolder">
                                                <i className="ti-stack-overflow" />
                                            </span>
                                            <span className="Title">Webhook Jobs</span>
                                        </>
                                    }
                                </NavLink>
                            }
                            {permissionsData.includes('ffm_platform_read') &&
                                <NavLink to="/a/platforms" className="nav-link" activeClassName="active">
                                    {
                                        isCollapsed ? <Tooltip overlayClassName='nav-item-tooltip' title="Platform" placement="right">
                                            <span className="Icon IconHolder">
                                                <i className="ti-server" />
                                            </span>
                                            <span className="Title">Platforms</span>
                                        </Tooltip> : <>
                                            <span className="Icon IconHolder">
                                                <i className="ti-server" />
                                            </span>
                                            <span className="Title">Platforms</span>
                                        </>
                                    }
                                </NavLink>
                            }
                            <div className='nav-link'>
                                {/* <span className='Icon' id='navIconUser'><i className="ti-user"/></span>

                                <UncontrolledPopover placement='right' target='navIconUser' trigger='hover'>
                                    <PopoverBody>
                                        <UserInfo/>
                                    </PopoverBody>
                                </UncontrolledPopover> */}

                                {
                                    isCollapsed ? <Popover content={<UserInfo />} placement="right" overlayClassName="popover-user">
                                        <span className='Icon' id='navIconUser'><i className="ti-user" /></span>
                                    </Popover> : <Popover content={<UserInfo />} placement="right" overlayClassName="popover-user">
                                        <div className='menu-item-wrapper'>
                                            <span className='Icon' id='navIconUser'><i className="ti-user" /></span>
                                            <span className="Title">User</span>
                                        </div>
                                    </Popover>
                                }

                            </div>
                            {(permissionsData.includes('ffm_setting_storage_read') || permissionsData.includes('ffm_setting_auto_read') ||
                                permissionsData.includes('ffm_setting_users_read')) &&
                                <NavLink to="/a/settings" className="nav-link"
                                    activeClassName="active" key="/a/settings">
                                    {
                                        isCollapsed ? <Tooltip overlayClassName='nav-item-tooltip' title="Settings" placement="right">
                                            <span className="Icon IconHolder">
                                                <i className="ti-settings" />
                                            </span>
                                            <span className="Title">Settings</span>
                                        </Tooltip> : <>
                                            <span className="Icon IconHolder">
                                                <i className="ti-settings" />
                                            </span>
                                            <span className="Title">Settings</span>
                                        </>
                                    }

                                </NavLink>
                            }
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

NavbarLeft.propTypes = {
    history: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickLinkNav: PropTypes.func.isRequired,
}

export default withRouter(NavbarLeft)
