import React from 'react'
import ReactDOM from 'react-dom'
import './env'
import 'antd/dist/antd.min.css'
import './statics/assets/css/libs/themify-icons.css'
import './statics/assets/css/libs/icomoon-icons.css'
import './scss/app.scss'
import App from './app/App'
import Router from 'react-router-dom/Router'
import getHistory from './store/getHistory'
import * as Sentry from "@sentry/react"
import {Integrations} from "@sentry/tracing"

Sentry.setTag('webapp', 'Fulfillment Webapp')
Sentry.init({
    dsn: "https://f0d679ab4e494986814dae8690f54dbe@sentry.merch8.com/20",
    integrations: [new Integrations.BrowserTracing(),],
    tracesSampleRate: 1.0,
    environment: "production"
})

ReactDOM.render(
    (
        <Router history={getHistory()}>
            <App/>
        </Router>
    ),
    document.getElementById('root'),
)
